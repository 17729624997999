import React from "react";

import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { ServicesTiles } from "../components/ServicesTiles";

import phone from "../assets/icons/phone.svg";
import { ImageGrid } from "../components/ImageGrid";
import { TestimonialsCarousel } from "../components/TestimonialsCarousel";
import { FAB } from "../components/FAB";
import { galleryImagesArray } from "../lib/constants";

export const Home = () => {
	return (
		<>
			<NavBar />
			<div className="above-the-fold fix-background">
				<div className="container valign-wrapper center-align">
					<div>
						<h1>American Waterproofing Company</h1>
						<h5>
							Exterior Balcony, Decking, Stairway, Floor Magnesite Fiberglass,
							Concrete Waterproofing Repair Contractor.
						</h5>
						<h3>Lic#1018649</h3>
						<div></div>
						<a className="cta-button" href="tel:+1-323-230-3174">
							<button>
								(323) 230-3174
								<img src={phone} alt="send" />
							</button>
						</a>
					</div>
				</div>
			</div>
			<div id="about-us" className="container division">
				<h1>Why choose us?</h1>
				<p>
					We realize that nobody wants to repair or replace their deck or
					balcony and stairs. You call a deck or balcony waterproofing
					contractor because you’re seeking honesty, experience, quality and a
					fantastic reputation.
				</p>
				<p>
					We can help you with all your necessary repairs and waterproofing of
					your exterior balcony Decking and stairways and at{" "}
					<strong>American Decking & Waterproofing Co</strong>, Our promise is
					to give you a 5-star service and workmanship on repairing all exterior
					flooring and installing state-of-the-art waterproofing. Our company
					has over 35 years of experience in decking and waterproofing all kinds
					of surfaces. We’ve built our brand with integrity on every project
					we’ve ever handled. We use only the finest materials, techniques and
					waterproofing professionals. We repair all existing waterproofing
					membrane systems used for balcony decking and stairways and install
					new one as prescribed from the manufacturers that we use.
				</p>
			</div>

			<ServicesTiles />
			<ImageGrid imageObjectArray={galleryImagesArray} />
			<TestimonialsCarousel />

			<FAB />
			<Footer />
		</>
	);
};
