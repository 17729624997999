import React, { useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";

import send from "../assets/icons/arrow-top-right-r.svg";
import phone from "../assets/icons/phone.svg";

const date = new Date();
const year = date.getFullYear();

export const Footer = () => {
	useEffect(() => {
		const elems = document.querySelectorAll(".modal");
		M.Modal.init(elems, {
			inDuration: 500,
			outDuration: 500,
		});
	}, []);

	return (
		<footer>
			<div id="termsOfService" className="modal">
				<div className="modal-content">
					<h4>Terms of Service</h4>
					<p>
						{" "}
						When you sign-in with us, you are giving American Decking &
						Waterproofing Co your permission and consent to send you email
						and/or SMS text messages. By checking the Terms and Conditions box
						and by signing in you automatically confirm that you accept all
						terms in this agreement.
					</p>

					<h5>SERVICE</h5>

					<p>
						We provide a service that currently allows you to receive requests
						for feedback, company information, promotional information, company
						alerts, coupons, discounts and other notifications to your email
						address and/or cellular phone or device. You understand and agree
						that the Service is provided "AS-IS" and that we assume no
						responsibility for the timeliness, deletion, mis-delivery or failure
						to store any user communications or personalization settings.
					</p>

					<p>
						You are responsible for obtaining access to the Service and that
						access may involve third party fees (such as SMS text messages,
						Internet service provider or cellular airtime charges). You are
						responsible for those fees, including those fees associated with the
						display or delivery of each SMS text message sent to you by us. In
						addition, you must provide and are responsible for all equipment
						necessary to access the Service and receive the SMS text messages.
						We do not charge any fees for delivery of email or SMS. This is a
						free service provided by us. However, please check with your
						internet service provider and cellular carrier for any charges that
						may incur as a result from receiving email and SMS text messages
						that we deliver upon your opt-in and registration with our email and
						SMS services. You can cancel at any time. Just text "STOP" to
						+15614492293. After you send the SMS message "STOP" to us, we will
						send you an SMS message to confirm that you have been unsubscribed.
						After this, you will no longer receive SMS messages from us.
					</p>

					<h5>YOUR REGISTRATION OBLIGATIONS</h5>

					<p>In consideration of your use of the Service, you agree to:</p>

					<ol>
						<li>
							provide true, accurate, current and complete information about
							yourself as prompted by the Service's registration form (such
							information being the "Registration Data")
						</li>

						<li>
							Maintain and promptly update the Registration Data to keep it
							true, accurate, current and complete. If you provide any
							information that is untrue, inaccurate, not current or incomplete,
							or we have reasonable grounds to suspect that such information is
							untrue, inaccurate, not current or incomplete, we have the right
							to suspend or terminate your account/profile and refuse any and
							all current or future use of the Service (or any portion thereof).
						</li>
					</ol>
				</div>
				<div className="modal-footer">
					<a
						href="#!"
						className="modal-close waves-effect waves-green btn-flat"
					>
						Close
					</a>
				</div>
			</div>

			<div id="privacyPolicy" className="modal">
				<div className="modal-content">
					<h4>Privacy Policy</h4>
					<h5>PRIVACY</h5>
					<p>
						The information provided during this registration is kept private
						and confidential, and will never be distributed, copied, sold,
						traded or posted in any way, shape or form. This is our guarantee.
					</p>
					<h5>INDEMNITY</h5>
					<p>
						You agree to indemnify and hold us,American Decking & Waterproofing
						Co HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
						FROM:
					</p>
					<ol>
						<li>THE USE OR THE INABILITY TO USE THE SERVICE;</li>
						<li>
							THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
							FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
							OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH
							OR FROM THE SERVICE;
						</li>
						<li>
							UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
							DATA;
						</li>
						<li>STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR</li>
						<li>ANY OTHER MATTER RELATING TO THE SERVICE.</li>
					</ol>
					By registering and subscribing to our email and SMS service, by
					opt-in, online registration or by filling out a card, "you agree to
					these TERMS OF SERVICE" and you acknowledge and understand the above
					terms of service outlined and detailed for you today.
				</div>
				<div className="modal-footer">
					<a
						href="#!"
						className="modal-close waves-effect waves-green btn-flat"
					>
						Close
					</a>
				</div>
			</div>

			<div className="row">
				<div className="col s12 m4">
					<h1>GIVE US A CALL</h1>
					<p className="hide-on-med-and-down">+(1)-(323) 230-3174</p>
					<a className="hide-on-med-and-up" href="tel:+1-323-230-3174">
						(323) 230-3174
						<img src={phone} alt="send" />
					</a>
					<h1>OFFICE ADDRESS</h1>
					<p className="hide-on-med-and-down">
						1234 E California Ave #106 Glendale, CA 91206 United States
					</p>
					<a
						target="_blank"
						rel="noopener noreferrer"
						className="hide-on-med-and-up"
						href="https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co/@34.149624,-118.237952,10z/data=!4m5!3m4!1s0x0:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518?hl=en-US"
					>
						1234 E California Ave #106 Glendale, CA 91206 United States
						<img src={send} alt="send" />
					</a>
				</div>
				<div className="col s12 m4">
					<h1>CITIES WE SERVICE</h1>
					<p>Burbank, CA</p>
					<p>La Canada Flintridge, CA</p>
					<p>South Pasadena, CA</p>
					<p>Los Angeles, CA</p>
					<p>Pasadena, CA</p>
					<p>West Hollywood, CA</p>
					<p>Altadena, CA</p>
					<p>Alhambra, CA</p>
					<p>San Marino, CA</p>
					<p>San Gabriel, CA</p>
					<p>​Monterey Park, CA</p>
					<p>East Los Angeles, CA</p>
					<p>Beverly Hills, CA</p>
					<p>Commerce, CA</p>
					<p>Huntington Park, CA</p>
				</div>
				<div className="col s12 m4">
					<h1>BUSINESS HOURS</h1>
					<p>Mon: 8:00 AM – 6:00 PM</p>
					<p>Tue: 8:00 AM – 6:00 PM</p>
					<p>Wed: 8:00 AM – 6:00 PM</p>
					<p>Thu: 8:00 AM – 6:00 PM</p>
					<p>Fri: 8:00 AM – 6:00 PM</p>
					<p>Sat: 8:00 AM – 4:00 PM</p>
					<p>Sun: Closed</p>
				</div>
			</div>
			<div className="policy">
				<p>American Decking & Waterproofing Co © {year} All Rights</p>
				<div>
					<a
						className="waves-effect waves-light btn modal-trigger"
						href="#termsOfService"
					>
						Terms of Service
					</a>
					<a
						className="waves-effect waves-light btn modal-trigger"
						href="#privacyPolicy"
					>
						Privacy Policy
					</a>
				</div>
			</div>
		</footer>
	);
};
