import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'materialize-css/dist/css/materialize.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles/App.scss";

import { Home } from "./views/Home";
import { Gallery } from "./views/Gallery";
import { Testimonials } from "./views/Testimonials";
import { ContactUs } from "./views/ContactUs";
import { Waterproofing } from "./views/services/Waterproofing";
// import { Walls } from "./views/services/Walls";
import { Stairways } from "./views/services/Stairways";
import { Balcony } from "./views/services/Balcony";
import ScrollToTop from "./lib/ScrollToTop";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/balcony-waterproofing" component={Balcony} />
          <Route exact path="/deck-waterproofing" component={Waterproofing} />
          {/* <Route exact path="/retaining-walls" component={Walls} /> */}
          <Route exact path="/stairway-repair" component={Stairways} />
          <Route exact path="/contact" component={ContactUs} />
        </Switch>
      </Router>
    );
  }
}

export default App;
