import React from "react";
import { Link } from "react-router-dom";
import { FAB } from "../../components/FAB";
import { Footer } from "../../components/Footer";
import { ImageGrid } from "../../components/ImageGrid";
import { NavBar } from "../../components/NavBar";
import { stairsGallery } from "../../lib/constants";

export const Stairways = () => (
	<>
		<NavBar />
		<div className="inner-page-banner inner-page-stairways">
			<div className="inner-service-overlay">
				<h1>Stairway Repair</h1>
			</div>
		</div>
		<div className="container division">
			<h1>What we do?</h1>

			<p>
				We have over 35 years experience in repairing exterior magnesite and
				concrete stairways. We repair and waterproof those as needed and we
				provide any other services that might be needed to service your
				staircases such as rotten wood replacement, metal railing welding repair
				and replacement of precast steps.
			</p>
		</div>

		<ImageGrid imageObjectArray={stairsGallery} />

		<Link to="/contact" className="cta-button">
			<button>Contact Us</button>
		</Link>
		<FAB />
		<Footer />
	</>
);
