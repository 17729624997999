import React from "react";

export const ImageGrid = ({ imageObjectArray }) => (
	<div className="container-fluid division" id="gallery">
		<h1>Gallery</h1>
		<div className="row">
			{imageObjectArray.map((image, idx) =>
				image.href ? (
					<a
						key={idx}
						href={image.href}
						target="_blank"
						rel="noopener noreferrer"
						className="col s12 m6 l4 overlay"
					>
						<img loading={'lazy'} src={image.source} alt={image.alt} />
					</a>
				) : (
					<div key={idx} className="col s12 m6 l4 overlay">
						<img loading={'lazy'} src={image.source} alt={image.alt} />
					</div>
				)
			)}
		</div>
	</div>
);
