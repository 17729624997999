import services from "../assets/services.jpeg";
import services1 from "../assets/services1.jpeg";
import services2 from "../assets/services2.jpeg";
import services3 from "../assets/services3.jpeg";
import services4 from "../assets/precast_steps.jpg";
import services5 from "../assets/stair.jpg";
import services6 from "../assets/waterproofing.jpg";
import services7 from "../assets/foundation_walls.jpg";
import services8 from "../assets/balcony_waterproofing 2.jpg";
import services9 from "../assets/balcony.jpg";
import services10 from "../assets/decking.jpg";
import services11 from "../assets/flooring.jpg";
import services12 from "../assets/stairways_1.jpeg";
import services13 from "../assets/stairways_2.jpeg";
import services14 from "../assets/stairways_3.jpeg";

import balcony1 from "../assets/Balcony/balcony_1.jpeg";
import balcony2 from "../assets/Balcony/balcony_2.jpeg";
import balcony3 from "../assets/Balcony/balcony_3.jpeg";
import balcony4 from "../assets/Balcony/balcony_4.jpeg";
import balcony5 from "../assets/Balcony/balcony_5.jpeg";
import balcony6 from "../assets/Balcony/balcony_6.jpeg";
import balcony7 from "../assets/Balcony/balcony_7.jpeg";
import balcony8 from "../assets/Balcony/balcony_8.jpeg";
import balcony9 from "../assets/Balcony/balcony_9.jpeg";
import balcony10 from "../assets/Balcony/balcony_10.jpeg";
import balcony11 from "../assets/Balcony/balcony_11.jpeg";
import balcony12 from "../assets/Balcony/balcony_12.jpeg";
import balcony13 from "../assets/Balcony/balcony_13.jpeg";
import balcony14 from "../assets/Balcony/balcony_14.jpeg";
import balcony15 from "../assets/Balcony/balcony_15.jpeg";
import balcony16 from "../assets/Balcony/balcony_16.jpeg";
import balcony17 from "../assets/Balcony/balcony_17.jpeg";
import balcony18 from "../assets/Balcony/balcony_18.jpeg";
import balcony19 from "../assets/Balcony/balcony_19.jpeg";

import decks1 from "../assets/Decks/decks_1.jpeg";
import decks2 from "../assets/Decks/decks_2.jpeg";
import decks3 from "../assets/Decks/decks_3.jpeg";
import decks5 from "../assets/Decks/decks_4.jpeg";
import decks4 from "../assets/Decks/decks_5.jpeg";

import stairs1 from "../assets/Stairs/stairs_1.jpeg";
import stairs2 from "../assets/Stairs/stairs_2.jpeg";
import stairs3 from "../assets/Stairs/stairs_3.jpeg";
import stairs4 from "../assets/Stairs/stairs_4.jpeg";
import stairs5 from "../assets/Stairs/stairs_5.jpeg";
import stairs6 from "../assets/Stairs/stairs_6.jpeg";
import stairs7 from "../assets/Stairs/stairs_7.jpeg";
import stairs8 from "../assets/Stairs/stairs_8.jpeg";
import stairs9 from "../assets/Stairs/stairs_9.jpeg";

import walls1 from "../assets/Walls/retaining_walls_1.jpeg";
import walls2 from "../assets/Walls/retaining_walls_2.jpeg";
import walls3 from "../assets/Walls/retaining_walls_3.jpeg";
import walls4 from "../assets/Walls/retaining_walls_4.jpeg";
import walls5 from "../assets/Walls/retaining_walls_5.jpeg";
import walls6 from "../assets/Walls/retaining_walls_6.jpeg";
import walls7 from "../assets/Walls/retaining_walls_7.jpeg";
import walls8 from "../assets/Walls/retaining_walls_8.jpeg";
import walls9 from "../assets/Walls/retaining_walls_9.jpeg";
import walls10 from "../assets/Walls/retaining_walls_10.jpeg";
import walls11 from "../assets/Walls/retaining_walls_11.jpeg";
import walls12 from "../assets/Walls/retaining_walls_12.jpeg";
import walls13 from "../assets/Walls/retaining_walls_13.jpeg";
import walls14 from "../assets/Walls/retaining_walls_14.jpeg";
import walls15 from "../assets/Walls/retaining_walls_15.jpeg";
import walls16 from "../assets/Walls/retaining_walls_16.jpeg";
import walls17 from "../assets/Walls/retaining_walls_17.jpeg";
import walls18 from "../assets/Walls/retaining_walls_18.jpeg";
import walls19 from "../assets/Walls/retaining_walls_20.jpeg";
import walls20 from "../assets/Walls/retaining_walls_21.jpeg";

export const galleryImagesArray = [
	{
		source: services,
		alt: "Waterproofing deck fixing",
	},
	{
		source: services1,
		alt: "Stairs waterproofing",
	},
	{
		source: services2,
		alt: "Stairs waterproofing",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co+-+Deck,+Stairs,+Balconies+-+Repair+Waterproof+Flooring+Contractor./@34.1496244,-118.2379518,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPn4LadXR1C6gSagcQcq69mrzHoQsr1Z5DnCVUG!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPn4LadXR1C6gSagcQcq69mrzHoQsr1Z5DnCVUG%3Dw203-h152-k-no!7i4608!8i3456!4m14!1m8!3m7!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!2sAmerican+Decking+%26+Waterproofing+Co+-+Deck,+Stairs,+Balconies+-+Repair+Waterproof+Flooring+Contractor.!8m2!3d34.1496244!4d-118.2379518!14m1!1BCgIgAQ!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518",
	},
	{
		source: services3,
		alt: "New balcony fiberglass waterproof system installation",
	},
	{
		source: services4,
		alt: "New waterproof membrane installation on stairs and precast steps",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipOrKP2_osEWj6wlLK0_78UGTDTMSOi2HXQgltSj!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOrKP2_osEWj6wlLK0_78UGTDTMSOi2HXQgltSj%3Dw203-h270-k-no!7i1920!8i2560!4m14!1m8!3m7!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!2sAmerican+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor!8m2!3d34.1496244!4d-118.2379518!14m1!1BCgIgAQ!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518",
	},
	{
		source: services5,
		alt: "Magnesite stairway repair and waterproof",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipO17zWuZA34ak69Vc8kOBAm-7SOKLUBKLV0tp-a!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipO17zWuZA34ak69Vc8kOBAm-7SOKLUBKLV0tp-a%3Dw203-h270-k-no!7i3024!8i4032!4m14!1m8!3m7!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!2sAmerican+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor!8m2!3d34.1496244!4d-118.2379518!14m1!1BCgIgAQ!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518",
	},
	{
		source: services6,
		alt: "waterproofing retaining walls rain protection",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipP3yakUWuWSEj4itUJu-VPJ1HIIvDMhc7qNqAs5!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipP3yakUWuWSEj4itUJu-VPJ1HIIvDMhc7qNqAs5%3Dw203-h152-k-no!7i2560!8i1920!4m14!1m8!3m7!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!2sAmerican+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor!8m2!3d34.1496244!4d-118.2379518!14m1!1BCgIgAQ!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518",
	},
	{
		source: services7,
		alt: "foundation walls",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co/@34.1498935,-118.2379494,3a,80.3y,90t/data=!3m8!1e2!3m6!1sAF1QipMIgXmBM7lJtT5r1zQlvlFYEfLA8eW1WPOHe7tA!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMIgXmBM7lJtT5r1zQlvlFYEfLA8eW1WPOHe7tA%3Dw203-h151-k-no!7i637!8i474!4m14!1m8!3m7!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!2sAmerican+Decking+%26+Waterproofing+Co!8m2!3d34.1496244!4d-118.2379518!14m1!1BCgIgAQ!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496244!4d-118.2379518",
	},
	{
		source: services8,
		alt: "balcony waterproofing",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipOGdBMXJzaPNwqi5ZwOzTQyrn7HwT9rn_Oy-U03!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOGdBMXJzaPNwqi5ZwOzTQyrn7HwT9rn_Oy-U03%3Dw203-h270-k-no!7i1200!8i1600!4m5!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496131!4d-118.2379519",
	},
	{
		source: services9,
		alt: "Balcony deck new fiberglass waterproof system installation for tile",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipMAWD-okChero7grCztGjq9myhrXth-9NRrJW7z!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMAWD-okChero7grCztGjq9myhrXth-9NRrJW7z%3Dw203-h270-k-no!7i1200!8i1600!4m5!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496131!4d-118.2379519",
	},
	{
		source: services10,
		alt: "decking deck waterproofing new decks",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipOGdBMXJzaPNwqi5ZwOzTQyrn7HwT9rn_Oy-U03!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOGdBMXJzaPNwqi5ZwOzTQyrn7HwT9rn_Oy-U03%3Dw203-h270-k-no!7i1200!8i1600!4m5!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496131!4d-118.2379519",
	},
	{
		source: services11,
		alt: "flooting floors floor waterproofing",
		href: "https://www.google.com/maps/place/American+Decking+%26+Waterproofing+Co-Deck,Stairs,Balconies-Repair+Waterproof+Flooring+Contractor/@34.1498935,-118.2379494,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPKY7crNexBcacFhcGUMcozjJly1tNYJuBYb_Xv!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPKY7crNexBcacFhcGUMcozjJly1tNYJuBYb_Xv%3Dw203-h152-k-no!7i1008!8i756!4m5!3m4!1s0x80c2c13c2a7a58a3:0xddb85b4ff24b75a1!8m2!3d34.1496131!4d-118.2379519",
	},
	{
		source: services12,
		alt: "Stairway repair",
	},
	{
		source: services13,
		alt: "Stairway repair",
	},
	{
		source: services14,
		alt: "Stairway repair",
	},
];

export const balconyGallery = [
	{
		source: balcony1,
		alt: "balcony, new balcony, California",
	},
	{
		source: balcony2,
		alt: "balcony, new balcony, California",
	},
	{
		source: balcony3,
		alt: "balcony, new balcony, California",
	},
	{
		source: balcony4,
		alt: "balcony, new balcony, California",
	},
	{
		source: balcony5,
		alt: "balcony, new balcony, California",
	},
	{
		source: balcony6,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony7,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony8,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony9,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony10,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony11,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony12,
		alt: "balcony, new balcony, California, balcony California, new balconies California",
	},
	{
		source: balcony13,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony14,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony15,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony16,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony17,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony16,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony17,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony18,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
	{
		source: balcony19,
		alt: "balcony, new balcony, California, balcony California, new balconies California, balcony repair",
	},
];

export const decksGallery = [
	{
		source: decks1,
		alt: "decks, decks construction, decks California, decking, american decking",
	},
	{
		source: decks2,
		alt: "decks, decks construction, decks California, decking, american decking",
	},
	{
		source: decks3,
		alt: "decks, decks construction, decks California, decking, american decking",
	},
	{
		source: decks4,
		alt: "decks, decks construction, decks California, decking, american decking",
	},
	{
		source: decks5,
		alt: "decks, decks construction, decks California, decking, american decking",
	},
];

export const stairsGallery = [
	{
		source: stairs1,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs2,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs3,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs4,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs5,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs6,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs7,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs8,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
	{
		source: stairs9,
		alt: "stairs, stair, stairway, staircase, stairway repair, magnesite, concrete stairways, California stairways repair",
	},
];

export const wallsGallery = [
	{
		source: walls1,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls2,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls3,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls4,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls5,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls6,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls7,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls8,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls9,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls10,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls11,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls12,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls13,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls14,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls15,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls16,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls17,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls18,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls19,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
	{
		source: walls20,
		alt: "walls, retaining walls, walls repair, california retaining walls repair",
	},
];
