import React, { useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";

import Comment from "../assets/icons/comment-dots-regular.svg";
import SMS from "../assets/icons/sms-solid.svg";
import WhatsApp from "../assets/icons/whatsapp-brands.svg";

export const FAB = () => {
	useEffect(() => {
		const contactModal = document.querySelectorAll(".contactModal");
		M.Modal.init(contactModal, {});
	});

	return (
		<>
			<a
				title="Chat with us!"
				className="btn-floating pulse contactModal waves-effect waves-light btn btn-large modal-trigger fab-trigger"
				href="#modal1"
			>
				<img src={Comment} alt="chat box" />
			</a>

			<div id="modal1" className="modal">
				<div className="modal-content">
					<h4>Chat with us</h4>

					<a
						className="sms"
						href="sms:+1-323-230-3174"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={SMS} alt="Text message icon" />
						<span>SMS</span>
					</a>
					<a
						className="whatsapp"
						href="https://wa.me/13232303174"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={WhatsApp} alt="WhatsApp icon" />
						<span>WhatsApp</span>
					</a>
				</div>
				<div className="modal-footer">
					<a
						href="#!"
						className="modal-close waves-effect waves-green btn-flat"
					>
						Close
					</a>
				</div>
			</div>
		</>
	);
};
