import React, { useEffect, useState } from "react";

import send from "../assets/icons/arrow-top-right.svg";

export const Form = () => {
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if (window.location.search.includes("success=true")) {
			setSuccess(true);
		}
	}, []);
	return (
		<div id="contact-form" className="container row">
			{success && (
				<h3 className="form-success">
					Your message has been received! We'll contact you soon.
				</h3>
			)}
			<form
				action="/contact/?success=true"
				className="col s12"
				data-netlify-recaptcha
				method="post"
				encType="multipart/form-data"
				name="User Inquiry"
			>
				<input type="hidden" name="form-name" value="User Inquiry" />
				<div className="row">
					<div className="input-field col s12 m6">
						<input
							className="validate"
							id="first_name"
							name="First_Name"
							pattern="[A-Za-z ]*"
							required
							title="First Name"
							type="text"
						/>
						<label htmlFor="first_name">First Name</label>
					</div>
					<div className="input-field col s12 m6">
						<input
							className="validate"
							id="last_name"
							name="Last_Name"
							pattern="[A-Za-z ]*"
							required
							title="Last Name"
							type="text"
						/>
						<label htmlFor="last_name">Last Name</label>
					</div>
				</div>
				<div className="row">
					<div className="input-field col s12 m6">
						<input
							className="validate"
							id="email"
							name="email"
							pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
							required
							title="Email"
							type="email"
						/>
						<label htmlFor="email">Email</label>
					</div>
					<div className="input-field col s12 m6">
						<input
							className="validate"
							id="phone_number"
							name="Phone_Number"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							required
							title="Phone Number"
							type="text"
						/>
						<label htmlFor="phone_number">Phone Number</label>
					</div>
				</div>
				<div className="row">
					<div className="input-field col s12">
						<input
							className="validate"
							id="address"
							name="address"
							required
							title="Address"
							type="text"
						/>
						<label htmlFor="address">Address</label>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<p>Attach Images or videos</p>
						<input
							alt="Attach image or vide"
							id="Image"
							name="image"
							title="Image"
							type="file"
						/>
					</div>
				</div>
				<div className="row">
					<div className="input-field col s12">
						<input
							autoComplete="off"
							className="validate"
							id="message"
							name="message"
							required
							title="Message"
							type="text"
						/>
						<label htmlFor="message">Message</label>
					</div>
				</div>
				<div data-netlify-recaptcha="true"></div>
				<button
					className="btn waves-effect waves-light send-btn"
					type="submit"
					name="Send Form"
				>
					Submit
					<img src={send} alt="send" />
				</button>
			</form>
		</div>
	);
};
