import React from "react";
import { NavBar } from "../components/NavBar";
import { ImageGrid } from "../components/ImageGrid";
import { Form } from "../components/Form";
import { Footer } from "../components/Footer";
import { galleryImagesArray } from "../lib/constants";

export const Gallery = () => (
	<>
		<NavBar />
		<ImageGrid imageObjectArray={galleryImagesArray} />
		<Form />
		<Footer />
	</>
)