import React from "react";

import { Form } from "../components/Form";
import airplane from "../assets/icons/airplane.svg";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { FAB } from "../components/FAB";

export const ContactUs = () => (
	<div>
		<NavBar />
		<div className="division">
			<h1>Contact Us</h1>
			<Form />
			
			<iframe
				className="map-embed"
				title="Office location"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6603.721990963089!2d-118.2379074462361!3d34.14989824425719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c109b1e49cbb%3A0xc9037f04490095ab!2s1234%20E%20California%20Ave%2C%20Glendale%2C%20CA%2091206%2C%20USA!5e0!3m2!1sen!2ssv!4v1630792539283!5m2!1sen!2ssv"
				height="400"
				allowFullScreen
				loading="lazy"
			></iframe>
			<a
				className="cta-button"
				href="https://www.google.com/maps/dir/?api=1&destination=34.1498982,-118.2379074"
				target="_blank"
				rel="noopener noreferrer"
			>
				<button className="draw meet">
					Take me there! <img src={airplane} alt="airplane logo" />
				</button>
			</a>
		</div>
		<FAB />
		<Footer />
	</div>
);
