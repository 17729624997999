import React from 'react';
import Slider from "react-slick";

import yelp from "../assets/icons/yelp.svg"
import google from "../assets/icons/google.svg"
import { FAB } from './FAB';

export const TestimonialsCarousel = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    vertical: false,
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  const testimonialsArray = [
    {
      id: 1,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/zD12ywyzA8gG2i-BIUR1Xw/60s.jpg",
      user: "Tim M.",
      review: "Excellent company! I found them on yelp and after seeing all the good reviews I met with them and they gave me a reasonable quote that I was happy with. Fast forward, weeks later we scheduled the work to be done on my balcony and all I can say is that their work and employeess are professional grade.       Thank you and I would not hesitate to recommend to anyone I know"
    },
    {
      id: 3,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/geR2BC2H2NzntYdt39w1eg/60s.jpg",
      user: "Rauben Z,",
      review: "Great workmanship and work ethic.  These guys did a marvelous job after we had a few disappointments with other companies either not showing up or not responding to calls, etc.  They were timely, responsive and did a quality job for a reasonable price.  Would highly recommend them :)"
    },
    {
      id: 4,
      platform: 'Google',
      img: "https://lh5.googleusercontent.com/-ACR-ACgZQU4/AAAAAAAAAAI/AAAAAAAAAAA/V22p-ibuF_g/w72-h72-p-rp-mo-br100/photo.jpg",
      user: "T. Mai,",
      review: "Excellent company! I found them on yelp and after reading all the good reviews I met with them and they gave me a reasonable quote that I was happy with. Fast forward, weeks later we scheduled the work to be done on my balcony and all I can say is that their work and employeess are professional grade. Thank you and I would not hesitate to recommend to anyone I know!"
    },
    {
      id: 5,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/5N1SA61A3dqzTM_wqf-Ffw/60s.jpg",
      user: "Stephen H.",
      review: "Yelp sent a request to this company by mistake, I was looking for epoxy floors and yelp sent them a request for a quote. This company was professional and courteous during this mixup."
    },
    {
      id: 6,
      platform: 'Google',
      img: "https://lh4.googleusercontent.com/-UgafSUNwz3A/AAAAAAAAAAI/AAAAAAAAAAA/5Aiudphxz4M/w72-h72-p-rp-mo-br100/photo.jpg",
      user: "Ruben Zadeh",
      review: "Professional, responsive, reasonable and attentive. You can't ask for more!"
    },
    {
      id: 7,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/s6Y0uPVmUmMLYGFNRhg_9A/60s.jpg",
      user: "Kevin L.",
      review: "American Decking & Waterproofing did a wonderful job for my condominium balcony near Hancock Park area.  After contacting, Mr. Georgi, the owner, came out right away and gave me a resonable estimate.  I was able to contact with Georgi at anytime, and his crews had never been late for work.  He finished his job within a schedule.  Before he gets paid, he did water test to make sure everything is OK.  He also gave us 10 years warrenty.  Highly recommended!!!"
    },
    {
      id: 8,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/6DGe2sqknpOFw3qOiy7fpw/60s.jpg",
      user: "Naeiri K.",
      review: "Georgi & team provided a very fair quote and did amazing, quick work that did not bother or inconvenience our tenants at all when they fixed and waterproofed the stairs to the second story unit at our duplex. Looks great, is structurally sound, and they have a solid warranty on their work."
    },
    {
      id: 9,
      platform: 'Google',
      img: "https://lh3.googleusercontent.com/-Y061VJDP7Cs/AAAAAAAAAAI/AAAAAAAAAAA/TJImf98jpro/w72-h72-p-rp-mo-br100/photo.jpg",
      user: "George Harvey",
      review: "Georgi came out to Long Beach on a Sunday afternoon to give us an estimate on our small patio and stairs. We appreciate his professionalism, honesty and attention to detail as seen in the quality if his work. I did not realize the labor intensive job he was bidding. We had no idea!! Our job is minuscule in comparison to the majority of his projects. He gave us some good pointers. Unfortunately we will have to wait and budget him in our timeline."
    },
    {
      id: 10,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/7G8G4Jw59RzGr7sh_zdwSA/60s.jpg",
      user: "Michael K.",
      review: "Georgi and American Decking & Waterproofing totally took care of me, on my recent balcony project.  Not only was he able to step up to help me stay on schedule;  they were timely, and professional, and the result is excellent.  Not only that, his pricing was extremely fair.  I'm a general contractor and I will happily work with this company again on future projects."
    },
    {
      id: 11,
      platform: 'Google',
      img: "https://lh3.googleusercontent.com/--tRXEzvqiec/AAAAAAAAAAI/AAAAAAAAAAA/aepkAUW3Q7c/w72-h72-p-rp-mo-br100/photo.jpg",
      user: "Dave Cassar",
      review: "Extremely professional and kept to a really tight schedule.  Excellent job. Highly recommend for those difficult jobs.  They came up with a waterproofing solution where others couldn’t."
    },
    {
      id: 12,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/jIv5oQfxcdjpwUimdxWDIw/60s.jpg",
      user: "Vic K.",
      review: "I am very impressed with the professionalism and service provided today. I called American decking yesterday for an estimate and explained that I need my patio decking completed as soon as possible. They were able to send someone this morning and had it done in three hours. Truly impressive! I would highly recommend using American decking!!!!"
    },
    {
      id: 13,
      platform: 'Google',
      img: "https://lh5.googleusercontent.com/-sPmWXlBWilw/AAAAAAAAAAI/AAAAAAAAAAA/oG55WHh6LE4/w72-h72-p-rp-mo-br100/photo.jpg)",
      user: "Celene Figeroa",
      review: "Georgi is a professional at his job and puts pride in his work. It was the only company who asked me to open the wall since the leak wasnt visible while the rest of the companies gave me higher bids. He is very knowledgeable and works fast. I'm very please with his job and definitely recommend him. His honesty is a highlight."
    },
    {
      id: 14,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/F8kHlaDAXAXTIDH47iMcAw/60s.jpg",
      user: "Stan L.",
      review: "This company was the only one that i can find that offered a 10 year warranty on my 2 patio and 2 front door landing at my rental property.  Also, they have the most positive reviews on google than any other waterproofing company that I can find.  Not only that, they even sent me pictures of the job as they are doing it since I don't reside there.  They said they will run water on the balcony to prove it doesn't leak before he wants to get paid.  They might not be the cheapest, but I believe they are the best."
    },
    {
      id: 15,
      platform: 'Google',
      img: "https://lh4.googleusercontent.com/-jFB3DEAuYrE/AAAAAAAAAAI/AAAAAAAAAAA/VS6-I70oSzc/w72-h72-p-rp-mo-br100/photo.jpg",
      user: "Princess Shaun",
      review: "I am very pleased with the job Mr. George did for my apartment complex. He is very knowledgeable, fast, professional and affordable. I would definitely be using for more properties.  Thanks"
    },
    {
      id: 16,
      platform: 'Yelp',
      img: "https://s3-media0.fl.yelpcdn.com/photo/iB0ifU9H8I5uXay88pOU5g/60s.jpg",
      user: "Deana D.",
      review: "George came out within hours after me contacting.  He was efficient, courteous, and knowledgeable.  He had the materials with him and fixed the problem in the same day."
    },
  ];

  return (
    <div id="testimonial" className="division">
      <h1>Testimonials</h1>
      <a href="https://www.yelp.com/biz/american-decking-and-waterproofing-glendale-2" target="_blank" rel="noopener noreferrer" className="testimonials-cta">Leave your review too!</a>

      <Slider className="reviews" { ...settings }>
        {testimonialsArray.map(review => (
          <div className="entry" key={review.id}>
            <div>
              <img className="circle responsive-img" src={review.img} alt="user profile" />
              <p className="username">
                { review.user }
                { review.platform === 'Google'
                  ? <img className="review-logo" src={google} title="Google" alt="Google reviews" />
                  : <img className="review-logo" src={yelp} title="Yelp" alt="Yelp reviews" />
                }
              </p>
              <p>{ review.review }</p>
            </div>
          </div>
        ))}
      </Slider>
      <FAB />
    </div>
  )
}