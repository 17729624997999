import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

import menu from "../assets/icons/menu-left.svg";
import Logo from "../assets/Logo.png";
import FB from "../assets/icons/facebook.svg";
import LinkedIn from "../assets/icons/linkedin.svg";

export const NavBar = () => {
	useEffect(() => {
		const sideNav = document.querySelectorAll(".sidenav");
		M.Sidenav.init(sideNav, {});
	});

	return (
		<>
			<nav>
				<button data-target="slide-out" className="sidenav-trigger">
					<img src={menu} alt="menu icon" />
				</button>
				<Link to="/">
					<img className="brand-logo" src={Logo} alt="American Decking logo" />
				</Link>
				<div className="social-icons hide-on-med-and-down">
					<a
						href="https://www.facebook.com/AmericanDeckingAndWaterproofing/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={FB} alt="Facebook logo" />
					</a>
					<a
						href="https://www.linkedin.com/company/american-decking-and-waterproofing-company"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={LinkedIn} alt="LinkedIn logo" />
					</a>
				</div>
			</nav>
			<ul id="slide-out" className="sidenav">
				<Link to="/">
					<li className="sidenav-close">Home</li>
				</Link>
				<Link to="/gallery">
					<li className="sidenav-close">Gallery</li>
				</Link>
				<Link to="/testimonials">
					<li className="sidenav-close">Testimonials</li>
				</Link>
				<Link to="/balcony-waterproofing">
					<li className="sidenav-close">Balcony Waterproofing</li>
				</Link>
				<Link to="/deck-waterproofing">
					<li className="sidenav-close">Deck Waterproofing</li>
				</Link>
				<Link to="/stairway-repair">
					<li className="sidenav-close">Stairway Repair</li>
				</Link>
				<Link to="contact">
					<li className="sidenav-close">Contact Us</li>
				</Link>
				<div className="social-icons hide-on-large-only">
					<a
						href="https://www.facebook.com/AmericanDeckingAndWaterproofing/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={FB} alt="Facebook logo" />
					</a>
					<a
						href="https://www.linkedin.com/company/american-decking-and-waterproofing-company"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={LinkedIn} alt="LinkedIn logo" />
					</a>
				</div>
			</ul>
		</>
	);
};
