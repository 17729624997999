import React from "react";

export const ServicesTiles = () => (
	<div id="services" className="services-background fix-background">
		<div className="container-fluid services-cards">
			<h1>What we offer</h1>

			<div className="row">
				<div className="col s12 m6 l4">
					<div className="card">
						<div className="waterproofing-image card-image" />
						<div className="card-content">
							<p>
								<strong>Deck Waterproofing:</strong> We use only high quality
								decking materials for waterproof or water-resistant application
								so that it remains relatively unaffected by water or resisting
								the access of water under specified conditions.
							</p>
							<p>
								We are waterproof and repair magnesite, fiberglass, concrete,
								plywood and tiled decks and we provide for all of our jobs 15
								years warranty.
							</p>
						</div>
					</div>
				</div>

				<div className="col s12 m6 l4">
					<div className="card">
						<div className="balcony-image card-image" />
						<div className="card-content">
							<p>
								<strong>Balcony Waterproofing:</strong> Waterproofing
								contractors are not all the same. Workmanship plays a huge
								factor in the final product. You need a deck and balcony
								waterproofing company that has the integrity to do the job right
								- cutting corners could cause $1000's in additional repair work
								later. Therefore, all of our specialists have over 10 to 15
								years of experience within our company to ensure that we provide
								you with the best possible outcome.
							</p>
						</div>
					</div>
				</div>
				<div className="col s12 m6 l4">
					<div className="card">
						<div className="stairways-image card-image" />
						<div className="card-content">
							<p>
								<strong>Stairway Repair:</strong> We have over 35 years
								experience in repairing exterior magnesite and concrete
								stairways. We repair and waterproof those as needed and we
								provide any other services that might be needed to service your
								staircases such as rotten wood replacement, metal railing
								welding repair and replacement of precast steps.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
