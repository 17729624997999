import React from "react";
import { Link } from "react-router-dom";
import { FAB } from "../../components/FAB";
import { Footer } from "../../components/Footer";
import { ImageGrid } from "../../components/ImageGrid";
import { NavBar } from "../../components/NavBar";
import { balconyGallery } from "../../lib/constants";

export const Balcony = () => (
	<>
		<NavBar />
		<div className="inner-page-banner inner-page-balcony">
			<div className="inner-service-overlay">
				<h1>Balcony</h1>
			</div>
		</div>
		<div className="container division">
			<h1>What we do?</h1>

			<p>
				Waterproofing contractors are not all the same. Workmanship plays a huge
				factor in the final product. You need a deck and balcony waterproofing
				company that has the integrity to do the job right - cutting corners
				could cause $1000’s in additional repair work later. Therefore, all of
				our specialists have over 10 to 15 years of experience within our
				company to ensure that we provide you with the best possible outcome.
			</p>
		</div>

		<ImageGrid imageObjectArray={balconyGallery} />

		<Link to="/contact" className="cta-button">
			<button>Contact Us</button>
		</Link>
		<FAB />
		<Footer />
	</>
);
