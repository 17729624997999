import React from "react";
import { Link } from "react-router-dom";
import { FAB } from "../../components/FAB";
import { Footer } from "../../components/Footer";
import { ImageGrid } from "../../components/ImageGrid";
import { NavBar } from "../../components/NavBar";
import { decksGallery } from "../../lib/constants";

export const Waterproofing = () => (
	<>
		<NavBar />
		<div className="inner-page-banner inner-page-waterproofing">
			<div className="inner-service-overlay">
				<h1>Deck Waterproofing</h1>
			</div>
		</div>
		<div className="container division">
			<h1>What we do?</h1>

			<p>
				We use only high quality decking materials for waterproof or
				water-resistant application so that it remains relatively unaffected by
				water or resisting the access of water under specified conditions.
			</p>

			<p>
				We are waterproof and repair magnesite, fiberglass, concrete, plywood
				and tiled decks and we provide for all of our jobs 15 years warranty.
			</p>
		</div>

		<ImageGrid imageObjectArray={decksGallery} />

		<Link to="/contact" className="cta-button">
			<button>Contact Us</button>
		</Link>
		<FAB />
		<Footer />
	</>
);
