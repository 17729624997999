import React from 'react'
import { FAB } from '../components/FAB'
import { Footer } from '../components/Footer'
import { Form } from '../components/Form'
import { NavBar } from '../components/NavBar'
import { TestimonialsCarousel } from '../components/TestimonialsCarousel'

export const Testimonials = () => (
	<>
		<NavBar />
		<TestimonialsCarousel />
		<Form />
		<FAB />
		<Footer />
	</>
)